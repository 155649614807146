<template>
	<div>
		<div class="flex justify_content_between align_items_center content-wrapper p_lr_20">
			<div class="flex justify_content_between align_items_center">
				<h2>物流单列表</h2>
			</div>
			<div class="flex justify_content_end align_items_center">
				<div class="m_r_20" v-if="code == 500">
					<span style="color:#f00;">任务处理中,请稍后...</span>
				</div>

				<div class="p_l_20">
					<el-button size="small" type="primary" @click="Exports(1)" class="m_r_10">导出物流</el-button>
					<el-button size="small" type="primary" v-if="isRole(['LogisticsManagement/Axportall'])"
						@click="getTableData1" class="m_r_10">全部导出</el-button>
					<!-- <el-button size="small" type="primary" v-if="isRole(['LogisticsManagement/deleteAll'])"
							@click="Exports(2)" class="m_r_10">全部导出2</el-button> -->
					<el-button size="small" type="primary" v-if="isRole(['LogisticsManagement/delete'])" @click="remove"
						class="m_r_10">删除记录</el-button>
					<el-button size="small" type="primary" v-if="isRole(['LogisticsManagement/deleteAll'])"
						@click="removeAll" class="m_r_10">全部删除</el-button>
					<el-button size="small" type="primary" @click="delyqs">删除已签收</el-button>
					<el-button size="small" type="primary" v-if="time_type == 0" @click="getTime" class="m_r_10">超时物流单
					</el-button>
					<el-button size="small" type="primary" v-if="time_type == 1" @click="getTime" class="m_r_10">全部物流单
					</el-button>
					<el-button size="small" type="primary" v-if="isRole(['LogisticsManagement/AllCreate'])"
						@click="getLogisticsDetail">刷新物流信息(收费)</el-button>
				</div>
				<div>
					<!-- 处理状态 -->
					<!-- <el-select v-model="search.dispose_status" @change="getTableData" size="small" placeholder="处理状态" class="m_r_10" clearable>
						<el-option :value="2" label="处理中"></el-option>
						<el-option :value="1" label="处理完成"></el-option>
						<el-option :value="3" label="全部"></el-option>
					</el-select> -->
				</div>
			</div>
		</div>
		<div class="flex justify_content_between align_items_center content-wrapper m_b_20" style="padding: 10px 20px;">
			<!-- 时间筛选 -->
			<div class="m_r_20">
				<el-select v-model="search.time_type" size="small" class="m_r_10" clearable>
					<el-option value="created_at" label="导入时间"></el-option>
					<el-option value="first_time" label="揽件时间"></el-option>
					<el-option value="delivery_time" label="发货时间"></el-option>
				</el-select>
				<el-date-picker class="m_r_20" v-model="search.time" type="daterange" size="small"
					value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				<span @click="getTime1" class="m_r_20 hand">近三天</span>
				<span @click="getTime2" class="m_r_20 hand">三天前一周</span>

				<el-select class="m_r_10" v-model="search.logistics_id" @change="getTableData" size="small"
					placeholder="物流公司" clearable>
					<el-option v-for="item in opt" :key="item.id" :value="item.id" :label="item.logistics_name">
					</el-option>
				</el-select>
				<!-- </div> -->
				<el-select v-model="search.search_type" size="small" class="m_r_10" clearable>
					<el-option value="logistics_odd" label="物流单号"></el-option>
					<el-option value="client_name" label="客户名"></el-option>
					<el-option value="line_num" label="线上订单号"></el-option>
					<el-option value="product_name" label="产品名"></el-option>
				</el-select>
				<el-select class="w_250 m_r_10" style="width: 250px;" filterable v-model="logistics_odd"
					v-if="search.search_type == 'client_name'||search.search_type == 'product_name'" size="small"
					clearable>
					<el-option v-for="item in opt1" :key="item.id" :value="item.name" :label="item.name"></el-option>
				</el-select>
				<el-input class="w_250 m_r_10" size="small" v-else v-model="logistics_odd" placeholder="请输入关键字"
					clearable></el-input>
				<el-button size="small" type="primary" @click="getTableData">搜索</el-button>

			</div>
			<div class="m_r_20" style="width: 120px">

				<!-- 物流状态 -->
				<!-- <el-select v-model="search.status" @change="getTableData" size="small" placeholder="物流状态" clearable>
					<el-option value="" label="全部"></el-option>
					<el-option value="运输中" label="运输中"></el-option>
					<el-option value="已签收" label="已签收"></el-option>
					<el-option value="代收" label="代收"></el-option>
					<el-option value="无物流、疑似无物流" label="无物流"></el-option>
					<el-option value="待查询" label="待查询"></el-option>
					<el-option value="异常件" label="异常件"></el-option>
				</el-select> -->
			</div>
		</div>

		<!-- <div class="flex align_items_center content-wrapper p_20 m_b_10">
			<div class="hand" style="margin-right: 100px;" @click="set1(2)">
				<span>处理中：</span>
				<span>{{ this.form.NoLogistics.num }} 单 
				</span>
			</div>

			<div class="hand" @click="set1(1)">
				<span>处理完成：</span>
				<span>{{ this.form.IssueLogistics.num }} 单 
				</span>
			</div>
		</div> -->

		<div class="content-wrapper m_b_20" style="padding: 5px 10px;">
			<div class="flex align_items_center" style="min-height: 50px;padding:0 10px">


				<el-col :span="3">
					<div class="hand" @click="set1(2)">
						<span>处理中：</span>
						<span>{{ this.form.InHand }} 单
						</span>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="hand" @click="set1(1)">
						<span>处理完成：</span>
						<span>{{ this.form.FigureOut }} 单
						</span>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="hand" @click="tuihuo()">
						<span>已退货：</span>
						<span>{{ this.form.sales_return.num }} 单
						</span>
					</div>
				</el-col>

				<!-- v-if="search.dispose_status == 1 || search.dispose_status == 2" -->

				<div class="hand" style="margin-left: 30px;">
					<el-button size="small" type="primary" @click="set2(3)" class="m_r_10">显示全部</el-button>
				</div>

			</div>
			<div class=" p_10">
				<el-row>
					<el-col>
						<div class="hand" @click="set('无物流')">
							<span>无物流：</span>
							<span>{{ this.form.NoLogistics.num }} 单 （{{this.form.NoLogistics.proportion}}）</span>
						</div>
					</el-col>
					<el-col>
						<div class="hand" @click="set('异常件')">
							<span>异常件：</span>
							<span>{{ this.form.IssueLogistics.num }} 单 （{{this.form.IssueLogistics.proportion}}）</span>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="hand" @click="set('运输中')">
							<span>运输中：</span>
							<span>{{ this.form.TransportLogistics.num }} 单
								（{{this.form.TransportLogistics.proportion}}）</span>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="hand" @click="set('代收')">
							<span>代收：</span>
							<span>{{ this.form.CollectionLogistics.num }} 单
								（{{this.form.CollectionLogistics.proportion}}）</span>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="hand" @click="set('一条物流')">
							<span>一条物流：</span>
							<span>{{ this.form.OneLogistics.num }} 单 （{{this.form.OneLogistics.proportion}}）</span>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="hand" @click="set('已签收')">
							<span>已签收：</span>
							<span>{{ this.form.SignLogistics.num }} 单 （{{ this.form.SignLogistics.proportion }}）</span>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="hand" @click="set(' ')">
							<span>全部：</span>
							<span>{{ this.form.AllLogistics }} 单 （100%） </span>
						</div>
					</el-col>
				</el-row>






			</div>
		</div>

		<div class="content-wrapper p_10 indexBanner">
			<div class="m_b_20">
				<page ref="Page" :total="total"></page>
			</div>
			<el-table :data="tableData" width="100%" ref="tables" @sort-change="sorts">
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="物流单号" prop="logistics_odd" width="150px" fixed></el-table-column>
				<el-table-column label="物流公司" prop="logistics.logistics_name"></el-table-column>
				<el-table-column label="发货时间" prop="delivery_time" width="160px" sortable="custom">
					<template slot-scope="scope">
						{{scope.row.delivery_time | format}}
					</template>
				</el-table-column>
				<el-table-column label="物流详情" prop="shipping_details" width="300px">
					<template slot-scope="scope">
						<span v-if="scope.row.last_info">
							<span v-html="scope.row.last_info"></span>
							<el-tag size="mini" class="hand" v-if="scope.row.all_info"
								@click="showInfo(scope.row.all_info)">...</el-tag>
						</span>
					</template>
				</el-table-column>


				<el-table-column label="条数" prop="traces_num" sortable="custom"></el-table-column>
				<el-table-column label="状态" prop="status">
					<template slot-scope="scope">
						<span v-if="scope.row.status == '疑似无物流'">无物流</span>
						<span v-else>{{scope.row.status}}</span>
					</template>
				</el-table-column>
				<el-table-column label="退件" prop="sales_return" sortable="custom">
					<template slot-scope="scope">
						<span>
							<span v-if=" scope.row.sales_return == 1 "></span>
							<span v-if="  scope.row.sales_return == 2 ">已退件</span>
						</span>
					</template>
				</el-table-column>
				<!-- <el-table-column label="问题" prop="remark" width="120px"></el-table-column> -->
				<el-table-column label="当前处理人" prop="treatment_scheme" width="120px">
					<template slot-scope="scope">
						<span v-if="
                scope.row.treatment_scheme.length &&
                scope.row.treatment_scheme[
                  scope.row.treatment_scheme.length - 1
                ].admin
              ">
							{{
                scope.row.treatment_scheme[
                  scope.row.treatment_scheme.length - 1
                ].admin.name
              }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="最后处理时间" prop="treatment_scheme" width="160px">
					<template slot-scope="scope">
						<span v-if="scope.row.treatment_scheme.length">
							{{
                scope.row.treatment_scheme[
                  scope.row.treatment_scheme.length - 1
                ].created_at | format }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="最后备注" prop="treatment_scheme" width="120px">
					<template slot-scope="scope">
						<span v-if="scope.row.treatment_scheme.length">
							{{
                scope.row.treatment_scheme[
                  scope.row.treatment_scheme.length - 1
                ].remark
              }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="处理状态" prop="treatment_scheme">
					<template slot-scope="scope">
						<span v-if="scope.row.treatment_scheme.length">
							<span v-if=" scope.row.dispose_status == 1 ">处理完成</span>
							<span v-if="  scope.row.dispose_status == 2 ">处理中</span>
						</span>
					</template>
				</el-table-column>
				<el-table-column label="停留时间" prop="last_time" width="100px" sortable="custom">
					<template slot-scope="scope">
						<span
							v-if="scope.row.last_time * 1">{{ Math.ceil((date.getTime()/1000 - scope.row.last_time)/60/60)}}
							时</span>
					</template>
				</el-table-column>
				<el-table-column label="产品名" prop="product_name" width="120px" sortable="custom"></el-table-column>

				<el-table-column label="线上订单号" prop="line_num" width="100px"></el-table-column>


				<el-table-column label="揽件时间 & 最新时间" prop="first_time" width="160px">
					<template slot-scope="scope">
						<span v-if="scope.row.last_time * 1">{{
              scope.row.last_time | format
            }}</span><br />
						<span v-if="scope.row.first_time * 1">{{
              scope.row.first_time | format
            }}</span>
					</template>
				</el-table-column>
				<el-table-column label="客户名" prop="client_name" width="120px"></el-table-column>
				<el-table-column label="收货人" prop="consignee" width="120px"></el-table-column>

				<el-table-column label="导入时间" prop="created_at" width="160px" sortable="custom">
					<template slot-scope="scope">
						{{scope.row.created_at | format}}
					</template>
				</el-table-column>

				<el-table-column label="操作" width="160px" prop="treatment_scheme" fixed="right">
					<template slot-scope="scope">
						<el-button size="small"
							v-if="isRole(['LogisticsManagement/sendOrders'])&& scope.row.dispose_status != 1"
							@click="edit(scope.row)">派单</el-button>
						<el-button size="small" @click="see(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Edit ref="Edit"></Edit>
			<See ref="See"></See>
		</div>
		<el-dialog :visible.sync="isShow1" append-to-body @close="(all_info = '' ,isShow1 = false)">
			<div class="p_20">
				<p v-html="all_info"></p>
			</div>
		</el-dialog>
	</div>
</template>
<script type="text/javascript">
	import methods from "./method";
	import Edit from "./edit/edit";
	import See from "./see/see";

	export default {
		data() {
			return {
				code: 111, //任务处理状态
				search: {
					time_type: "",
					search_type: 'logistics_odd',
					time: [],
					status: "",
					logistics_id: "",
					dispose_status: 3, //处理状态
					sales_return: "", //已退货
				},
				order_by_field: '',
				order: '',
				logistics_odd: "",
				time: [],
				time_type: 0,
				small_status: '',
				opt: [],
				isShow1: false,
				tableData: [],
				tableData1: [],
				id: "1",
				total: "",
				date: new Date(),
				form: {
					IssueLogistics: {
						num: 0,
						proportion: "0%"
					},
					AllLogistics: 0,
					CollectionLogistics: {
						num: 0,
						proportion: "0%"
					},
					OneLogistics: {
						num: 0,
						proportion: "0%"
					},
					TransportLogistics: {
						num: 0,
						proportion: "0%"
					},
					SignLogistics: {
						num: 0,
						proportion: "0%"
					},
					NoLogistics: {
						num: 0,
						proportion: "0%"
					},
					sales_return: {
						num: 0,
						proportion: "0%"
					},
					FigureOut: 0,
					InHand: 0
				}, //统计数据
				all_info: '',
				productList: [],
				clientList: [],
				opt1: [],
			};
		},
		methods: methods,
		components: {
			Edit,
			See,
		},
		watch: {
			search: {
				handler: function() {

					this.$refs.Page.pagination.current = 1;
					// this.small_status = '';
					if (this.search.search_type == 'client_name') {
						// this.getClient()
						this.opt1 = this.clientList;
					}

					if (this.search.search_type == 'product_name') {
						// this.getProduct()
						this.opt1 = this.productList

					}
					this.getTableData()
				},
				deep: true
			}
		},
		mounted() {
			this.getTableData();
			this.getLogistics();
			this.renwu();
			this.getClient();
			this.getProduct();
			// console.log(this.$refs.tables)
			// this.$refs.tables.scrollBy(this.$refs.tables.bodyWidth,0)
			// document.getElementById('tab').scrollLeft(document.getElementById('tab').scrollWidth);
			// this.renwu();
		},
	};
</script>
<style scoped>
	.el-select {
		width: 120px;
	}

	.el-date-editor {
		width: 255px;
	}

	/deep/.el-table__body {
		font-size: 12px;
		color: #000;
	}

	/deep/.el-table th {
		font-size: 12px;
		color: #000;
	}

	/deep/.el-col {
		width: 14%;
	}

	/deep/.el-col span {
		display: inline-block;
	}

	/deep/.el-col span:first-child {
		display: inline-block;
		width: 80px;
		text-align: right;
	}
</style>
